<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-09-26 15:44:50
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-07-09 17:15:50
 * @FilePath: \official_website\src\views\componets\Breadcrumb.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="scienceBreadcrumb"> 
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/science' }"
        >学术园地</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{text}}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
    
<script>
export default {
  name: "",
  props: {
    text: {
      type: String,
      default: function() {
        return ""
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>
    
<style lang="scss">
#scienceBreadcrumb {
  width: 100%;
  padding: 1rem 0.5rem;
  position: fixed;
  left: 0;
  top: 3.75rem;
  background-color: #fff;
  z-index: 10;
  .el-breadcrumb {
    font-size: 0.75rem !important;
  }
  .el-icon-arrow-right {
    color: #666;
  }
}
</style>